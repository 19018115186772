import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { houseWithGarden, houses, handShake, triangularRuler } from '../../../shared/assets/emojis';

import SELLER_DOCUMENTS from '../../api/ressources/SellerApp/documents';
import DOCUMENT_TYPES from '../../api/ressources/App/documentTypes';
import DOCUMENTS from '../../../shared/apiPublic/ressources/documents';

export const getDocuments = createAsyncThunk(
  '/documents',
  async (saleId) => {
    const response = await SELLER_DOCUMENTS.getDocuments(saleId)
    return response.data
  }
);

export const addDocument = createAsyncThunk(
  '/add_document',
  async ({saleId, form}) => {
    const response = await SELLER_DOCUMENTS.uploadDocument(saleId, form)
    return response.data
  }
);

export const getDocumentTypes = createAsyncThunk(
  '/document_types',
  async (propertyKindGroup) => {
    const response = await DOCUMENT_TYPES.getDocumentTypes({[`q[relevant_${propertyKindGroup}_eq]`]: true })
    return response.data
  }
);

export const deleteDocument = createAsyncThunk(
  '/delete_document',
  async (documentId) => {
    const response = await DOCUMENTS.deleteDocument(documentId)
    return response.data
  }
);

const initialState = {
  documents: [],
  mandatoryDocs: [],
  uploadedOptionalDocs: [],
  allDocs: [],
  requestState: '',
  lastDocumentUpload: null,
  isLoading: false,
  documentTypes: [],
  groupedDocumentTypes: {},
  addedInputs: {property: [], diagnostic: [], condo: [], acquisition: []},
  docTypeInfos: {
    property: { name: 'Documents liés au bien', emoji: houseWithGarden },
    diagnostic: { name: 'Diagnostics liés au bien', emoji: triangularRuler },
    condo: { name: 'Documents de copropriété', emoji: houses },
    acquisition: { name: "Documents liés à l'acquisition", emoji: handShake }
  }
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    fileEdit: (state, action) => {
      state.lastDocumentUpload = action.payload
      state.isLoading = true
    },
    addInput: (state, action) => {
      const findAddedDoc = state.documentTypes.find(doc => doc.id === Number(action.payload))
      const copy = state.addedInputs
      copy[findAddedDoc.category_slug].push({id: findAddedDoc.id, name: findAddedDoc.name, slug: findAddedDoc.slug})
      state.addedInputs = copy
    },
    updateInputs: (state, action) => {
      const dataFile = action.payload
      const copy = {...state.addedInputs}
      const filteredDocs = copy[dataFile.categorySlug].filter(doc => doc.slug !== dataFile.slug)
      copy[dataFile.categorySlug] = filteredDocs
      state.addedInputs = copy
    },
    handleDocChanges: (state, { payload: propertyKindGroup }) => {
      state.mandatoryDocs = filterMandatoryDocsFilter(state.groupedDocumentTypes, propertyKindGroup)
      state.uploadedOptionalDocs = filterUploadedOptionalDocs(state.groupedDocumentTypes, propertyKindGroup)
      state.allDocs = allDocs(state.groupedDocumentTypes, propertyKindGroup)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDocuments.pending, (state) => {
      state.documents = initialState.documents
      state.requestState = 'pending'
    })
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload
      state.groupedDocumentTypes = handleDocTypeOrder(state)
      state.requestState = 'done'
    })
    builder.addCase(getDocumentTypes.fulfilled, (state, { payload }) => {
      state.documentTypes = payload
    })
    builder.addCase(addDocument.fulfilled, (state, { payload }) => {
      const newDoc = payload
      let copy = {...state.groupedDocumentTypes}
      let documentType = copy[newDoc.type.category_slug].find(doc => doc.slug === newDoc.type.slug)
      documentType.documents.push(newDoc)
      state.groupedDocumentTypes = copy
      state.lastDocumentUpload = newDoc.type.id
      state.isLoading = false
    })
    builder.addCase(deleteDocument.fulfilled, (state, { payload }) => {
      const updatedDocs = state.documents.filter((doc) => doc.id !== payload.id);
      state.documents = updatedDocs;
      state.groupedDocumentTypes = handleDocTypeOrder(state)
    });
  },
})

const handleDocTypeOrder = (state) => {
  const saleDocsAndDocTypes = mergeSaleDocsAndDocTypes(state.documentTypes, state.documents)
  const groupByCategoryDocs = handleGroupByCategoryDocs(saleDocsAndDocTypes)
  return handleOrderedDocsByCategory(groupByCategoryDocs, state.docTypeInfos)
}

const filterMandatoryDocsFilter = (groupedDocumentTypes, kind) => {
  return Object.entries(groupedDocumentTypes).map(([_, docs]) => (
    docs.filter(doc => doc[`mandatory_${kind}`] === true)
  )).flat();
}

const filterUploadedOptionalDocs = (groupedDocumentTypes, kind) => {
  return Object.entries(groupedDocumentTypes).map(([_, docs]) => (
    docs.filter(doc => doc[`mandatory_${kind}`] !== true && doc.documents.length > 0 )
  )).flat();
}

const allDocs = (groupedDocumentTypes, kind) => {
  return Object.entries(groupedDocumentTypes).map(([_, docs]) => (
    docs.map(doc => doc)
  )).flat();
}

const mergeSaleDocsAndDocTypes = (docTypes, docs) => {
  let saleDocsAndDocTypes = []
  docTypes.map(docType => {
    let docTypeCopy = {...docType}
    let doc = docs.filter(doc =>
      doc.type.id === docType.id
    )

    if(docTypeCopy['documents'] === undefined) docTypeCopy['documents'] = []
    docTypeCopy['documents'].push(...doc)
    saleDocsAndDocTypes.push(docTypeCopy)
  })

  return saleDocsAndDocTypes;
}

const handleGroupByCategoryDocs = (saleDocsAndDocTypes) => {
  const docsGroupedByCategory = {}

  saleDocsAndDocTypes.map(doc => {
    if (docsGroupedByCategory[doc.category_slug] === undefined) {
      docsGroupedByCategory[doc.category_slug] = []
    }

    docsGroupedByCategory[doc.category_slug].push(doc)
  })

  return docsGroupedByCategory;
}

const handleOrderedDocsByCategory = (docsGroupedByCategory, docTypeInfos) => {
  // Order object by category
  let keyOrder = Object.keys(docTypeInfos);
  const compareByOrder = (a, b) => {
    return keyOrder.indexOf(a) - keyOrder.indexOf(b);
  }
  // create an array of the object keys and sort it based on the custom order
  let sortedKeys = Object.keys(docsGroupedByCategory).sort(compareByOrder);
  // create a new object with the sorted keys and their corresponding values
  let orderedDocsByCategory = {};
  sortedKeys.forEach((key) => {
    orderedDocsByCategory[key] = docsGroupedByCategory[key];
  });

  return orderedDocsByCategory
}

export const { fileEdit, addInput, updateInputs, addPropertyKindGroup, handleDocChanges } = documentsSlice.actions;

export default documentsSlice.reducer;
