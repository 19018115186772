import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { D_CalendarStar } from 'hosman-material';
import { longDate, isoDateTime, displayTime } from '../../../../../../../../shared/utils/formattedDates';
import * as dayjs from 'dayjs';
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

import { Modal, Switch, Button, InfoIcon } from 'hosman-material';

export const NewEventModal = ({ handleNewOrUpdateEvent, newEventData, setNewEventModalOpen, existingEvents }) => {
  const [visitInBetween, setvisitInBetween] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState(displayTime(newEventData.start));
  const [endTime, setEndTime] = useState(displayTime(newEventData.end));
  const [allDay, setallDay] = useState(false)
  const [isRecurring, setIsRecurring] = useState(false)
  const [slot, setSlot] = useState({ id: newEventData.id, start: isoDateTime(newEventData.start), end: isoDateTime(newEventData.end), isWeeklyRecurring: false })
  const [slots, setSlots] = useState([])

  useEffect(() => {
    const start = new Date(newEventData.start)
    const end = new Date(newEventData.start)
    start.setHours(startTime.split(':')[0])
    start.setMinutes(startTime.split(':')[1])
    end.setHours(endTime.split(':')[0])
    end.setMinutes(endTime.split(':')[1])
    setSlot({ id: newEventData.id, start: isoDateTime(start), end: isoDateTime(end), isWeeklyRecurring: false })
  }, [startTime, endTime]);

  useEffect(() => {
    let newSlots = [slot]

    if (isRecurring) {
      for (let i = 1; i < 4; i++) {
        const nextSlot = { ...slot }
        let nextSlotStart = new Date(slot.start)
        let nextSlotEnd = new Date(slot.end)

        nextSlotStart.setDate(nextSlotStart.getDate() + i*7);
        nextSlotEnd.setDate(nextSlotEnd.getDate() + i*7);

        nextSlot.start = isoDateTime(nextSlotStart)
        nextSlot.end = isoDateTime(nextSlotEnd)
        newSlots.push(nextSlot)
      }
    }

    checkVisitsInBetweenSlots(newSlots)

    setSlots(newSlots)
  }, [slot, isRecurring]);

  const checkVisitsInBetweenSlots = (newSlots) => {
    setvisitInBetween(false);
    newSlots.map((newSlot) => {
      if (existingEvents.filter((event) => !event.canceled && event.typeSlug === 'visit' && dayjs(event.start).isBetween(newSlot.start, newSlot.end)).length > 0) {
        setvisitInBetween(true);
      }
    })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    let newSlots = [...slots]
    if (allDay) newSlots = formatAllDay(slots)

    handleNewOrUpdateEvent(newSlots)
  }

  const formatAllDay = (paramSlots) => {
    const formattedSlots = []

    paramSlots.map(paramSlot => {
      const start = new Date(paramSlot.start)
      const end = new Date(paramSlot.start)
      start.setHours(7)
      start.setMinutes(0)
      end.setHours(21)
      end.setMinutes(0)

      formattedSlots.push({ id: paramSlot.id, start: isoDateTime(start), end: isoDateTime(end), isWeeklyRecurring: false })
    })

    return formattedSlots;
  }

  return (
    <Modal isOpen closeModal={() => setNewEventModalOpen(false)}>
      <div className='flex flex-col gap-6 items-center p-6 w-full mx-auto mt-6 text-ih-indigo'>
        <p className='h3 text-center flex flex-col'>
          Quel créneau souhaitez-vous rendre
          <span className='text-ih-red'>indisponible à la visite ?</span>
        </p>
        <div className='flex gap-3 items-center'>
          <FontAwesomeIcon icon={D_CalendarStar} size='lg' />
          <p>{longDate({ date: newEventData.start, capitalized: true })}</p>
        </div>

        <Switch
          checked={allDay}
          handeChange={() => setallDay(prev => !prev)}
          label={<p className='text-ih-indigo font-semibold text-sm'>Toute la journée</p>}
        />

        {!allDay &&
          <div className='flex gap-3 items-center'>
            <input
              type='time'
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              step='900'
              min='07:00'
              max='21:00'
              className='text-ih-indigo border-ih-blue-30 rounded-2xl'
            />
            <p>-</p>
            <input
              type='time'
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              step='900'
              min='07:00'
              max='21:00'
              className='text-ih-indigo border-ih-blue-30 rounded-2xl'
            />
          </div>
        }

        {!slot.id &&
          <div className='flex items-center gap-3'>
            <input
              id='recurring'
              name='recurring'
              type='checkbox'
              checked={isRecurring}
              onChange={() => setIsRecurring(!isRecurring)}
            />
            <label htmlFor='recurring' className='w-96 text-sm'>{`Mettre cette même indisponibilité tous les ${newEventData.start.toLocaleDateString('fr-FR', { weekday: 'long' })}s sur les 4 prochaines semaines`}</label>
          </div>
        }

        {visitInBetween &&
          <div className='w-96 flex gap-2'>
            <InfoIcon bgColor='bg-ih-red-30' iconColor='text-ih-red' />
            <p className='text-xs'>
              {`${isRecurring ? 'Attention, une visite déjà planifiée sur au moins un des créneaux ajoutés' : 'Attention une visite est déjà planifiée sur ce créneau'}. Veuillez prévenir votre expert si vous devez l'annuler.`}
            </p>
          </div>
        }

        <div className='flex flex-col md:flex-row gap-3'>
          <Button label='Annuler' type='tertiary' onClick={() => setNewEventModalOpen(false)} small />
          <Button label='Enregistrer cette indisponibilité' onClick={handleSubmit} isLoading={isSubmitting} small disabled={visitInBetween}/>
        </div>
      </div>
    </Modal>
  );
};

// PropTypes
NewEventModal.propTypes = {
  handleNewOrUpdateEvent: PropTypes.func,
  newEventData: PropTypes.object,
  setNewEventModalOpen: PropTypes.func,
  existingEvents: PropTypes.array
};
