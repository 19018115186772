import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SelectInput } from 'hosman-material';

export const SelectInputFile = ({ optionList, categorySlug = '', handleChange }) => {
  const memoizedOptions = useMemo(() => setOptions(), [optionList]);

  function setOptions() {
    return optionList.filter(option => option.category_slug === categorySlug)
  }

  return(
    <div className='bg-ih-blue-5 border border-ih-blue-10 rounded-2xl p-4'>
      <div className='flex flex-col md:flex-row justify-between items-start md:items-center mb-5 md:gap-10'>
        <div className='font-semibold text-sm md:mb-1'>D'autres documents à nous transmettre ?</div>
      </div>

      <SelectInput
        options={memoizedOptions}
        placeholder='Ajouter un type de document...'
        handleChange={handleChange}
        name='newFile'
      />
    </div>
  )
};

SelectInputFile.propTypes = {
  optionList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  categorySlug: PropTypes.string
}
