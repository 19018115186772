import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'hosman-material';

export const FileInputContainer = ({ children, file, mandatoryLabel = false, uploadedLabel = false }) => {
  return(
    <div data-testid='fileInputContainer' className='bg-ih-blue-5 border border-ih-blue-10 rounded-2xl p-4'>
      <div className='flex flex-col md:flex-row justify-between items-start md:items-center mb-5 md:gap-10'>
        <div className='font-semibold text-sm md:mb-1 text-ih-indigo'>{file.name}</div>
        {uploadedLabel > 0 ?
          <Label color='bg-ih-green'>Ajouté</Label>
        :
          mandatoryLabel && <Label color='bg-ih-blue'>Obligatoire</Label>
        }
      </div>

      {children}
    </div>
  )
};

FileInputContainer.propTypes = {
  file: PropTypes.object.isRequired,
  uploadedLabel: PropTypes.bool,
  mandatoryLabel: PropTypes.bool,
  children: PropTypes.array
}
